const BaseURL = 'https://eressea-crm.melian.me/api';

const titles = {
  siteName: 'Eressea CRM',
}

const logos = {
  logoTopbar: 'linkspace_logo_white.svg',
  logoLogin: 'linkspace_logo_white.svg',
  backgroundLogin: 'background.jpg',
}
export { 
  BaseURL,
  titles,
  logos
}
